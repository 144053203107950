body {
  background-color: #111111;
  color: #ffffff;
}
.card {
  background-color: #111111 !important;
}
.olto_theme_card {
  border: 1px solid #ffffff;
}
.primary_button {
  background-color: #1de7b8 !important;
  color: #000000 !important;
}
button {
  border-radius: 0px !important;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #222222 inset !important;
  -webkit-text-fill-color: #ffffff !important;
  caret-color: #ffffff !important;
}
input {
  background-color: #222222 !important;
  border: none !important;
  color: #ffffff !important;
}
.pricing_list li {
  background-color: inherit !important;
  border: 1px solid #111111 !important;
}
.theme_color {
  color: #1de7b8;
}
.authBgImage {
  /* background-image: url(./assets/images/pexels-nataliya-vaitkevich-7172774.jpg);
  background-size: cover;
  background-position-y: center;
  background-repeat: no-repeat; */
  height: 100vh;
}
.auth_card {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgb(26, 232, 184, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.cursor_pointer {
  cursor: pointer;
}
.links {
  text-decoration: underline;
  cursor: pointer;
  color: #1de7b8;
}
.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #111111;
  height: 56px;
  z-index: 100;
}
.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}
.toolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}
.toolbar_navigation-items a {
  color: #ffffff;
  text-decoration: none;
}
.toolbar_navigation-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.toolbar_navigation-items li {
  padding: 0 0.5rem;
}
.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: aliceblue;
}
.toolbar_navigation-items {
}
.toolbar__logo {
  margin-left: 10px;
}
.spacer {
  flex: 1;
}
.toolbar_profile_icon {
  color: #ffffff;
  font-size: 22px;
}

.sideDrawer_icon {
  font-size: 30px;
  margin-right: 10px;
}

.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__line {
  width: 30px;
  height: 2px;
  background: white;
}

.side-drawer {
  height: 100%;
  background-color: #111111;
  color: #ffffff;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 250px;
  z-index: 100;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.table {
  color: #ffffff;
}

tr {
  border: 1px solid #2f393a !important;
}
td,
th {
  border-top: 1px solid #2f393a !important;
}

thead tr th {
  border-bottom: 1px solid #2f393a !important;
}

.side-drawer ul {
  list-style: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer a:hover,
.side-drawer a:active,
.side_nav:hover{
  color: #1de7b8;
}
.signal_img{
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}
.theme_bg_color{
  background-color: #1de7b8;
}
.acive_nav{
  color: #1de7b8 !important;
  font-weight: 700;
}
.custom_modal_style{
  background-color: #111111;
}
.close_modal_icon{
  color: #ffffff;
}
.close_modal_icon:hover{
  color: #ffffff;
}
.side-drawer a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
}
.socio_auth_btn {
  width: 80%;
}
.side-drawer.open {
  transform: translateX(0);
}
.layout_container {
  margin-left: 250px;
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3800;
}

.profile_image {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  border: 1px solid gray;
}

.profile_image img {
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}

.message_screen_container {
  z-index: 2500;
  background-color: #ffffff;
  top: 56px;
  left: 0;
  right: 0;
  height: 100%;
  position: fixed;
  /* max-height: 100vh; */
}
.message_wrapper {
  position: fixed;
  z-index: 2500;
  background-color: #ffffff;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  /* max-height: 100vh; */
}

.image_edit_icon {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background-color: gray;
  color: #ffffff;
  cursor: pointer;
  right: 8px;
  top: 70%;
  position: absolute;
}

.trade_signal_list_wrapper {
  width: 100%;
}

.trade_signal_list_wrapper li {
  margin-bottom: 15px;
}
.init_chat_icon {
  font-size: 30px;
}

.chat_box {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 208, 0, 0.581);
  display: flex;
  align-items: center;
  justify-content: center;
}

.signal_tips_icons {
  font-size: 22px;
}
.round_message_count {
  background: #3096f3;
  color: #ffffff;
  border-radius: 100px;
  height: max-content;
  width: max-content;
  padding: 0 6px;
  font-size: 12px;
}

.chat_wrapper {
  overflow-y: auto;
  height: 70vh;
}
.display_chat_wrapper {
  height: 80%;
  overflow-y: auto;
  background-color: #fffafa;
}
.chat_form_section {
  left: 0;
  right: 0;
  bottom: 0;
}
.chat_textarea {
  width: 85%;
}
.chat_textarea::placeholder {
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #979aa0;
}
.chat_icons {
  color: gray;
}
.home_chat_row div {
  background: gray;
  color: #ffffff;
  max-width: 300px;
}
.away_chat_card {
  max-width: 300px;
}
.rotated {
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.paperClipIcon_image {
  width: 27px;
  height: 27px;
}
.custom_angle_div {
  background: #ffffff;
  position: absolute;
  left: -10px;
  top: 0;
  clip-path: polygon(100% 0%, 0% 0%, 30% 100%, 30% 100%);
  width: 36px;
  height: 15px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.custom_angle_div_away {
  background: gray;
  position: absolute;
  right: -10px;
  top: 0;
  clip-path: polygon(30% 100%, 30% 100%, 0% 0%, 100% 0%);
  width: 36px;
  height: 15px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  transform: rotateY(180deg);
  z-index: 1;
}
.polygon-wrap {
  position: absolute;
  width: 500px;
  height: 275px;
  top: 0;
  left: 0;
  -webkit-clip-path: polygon(50% 95%, 50% 95%, 50% 95%);
}

.main_layout_wrapper {
  position: relative;
}

.logo_img {
  width: 150px;
}

@media (max-width: 768px) {
  .layout_container {
    margin-left: 0px;
  }
  .toolbar_navigation-items {
    /* display: none; */
  }
  .trade_signal_list_wrapper {
    width: 100%;
  }
  .signal_tips_list {
    font-size: 12px;
    flex-wrap: wrap;
  }
  .trade_signal_list_wrapper li {
    margin-bottom: 23px;
  }
  /* .profile_image{
    width: 150px;
    height: 150px;
  } */
  html,
  body {
    height: 100%;
  }
  .chat_form_section {
    /* margin-top: 20px; */
    bottom: unset;
  }
  .message_screen_container {
    /* height: unset; */
    overflow-y: auto;
    padding-bottom: 40%;
  }
  .socio_auth_btn {
    width: 100%;
  }
  button b {
    font-size: 16px;
  }
}
